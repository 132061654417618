import NetUtils from "../utils/NetUtils"
import NetAddr from "./NetAddr"

export default {
  // 登录
  adminLogin(params) {
    return NetUtils.post("adminLogin", NetAddr.adminLogin, params, true)
  },
  // 注册用户
  adminRegister(params) {
    return NetUtils.post("adminRegister", NetAddr.adminRegister, params, true)
  },
  // 发送验证码
  getVerifyCode(params) {
    return NetUtils.get("getVerifyCode", NetAddr.getVerifyCode, params)
  },
  // 角色管理 查询所有角色
  roleControlList(params) {
    return NetUtils.get("roleControlList", NetAddr.roleControlList, params)
  },
  // 角色管理 新增或修改
  roleControlAdd(params) {
    return NetUtils.post("roleControlAdd", NetAddr.roleControlAdd, params, true)
  },
  // 角色管理 删除
  roleControlDel(params) {
    return NetUtils.post("roleControlDel", NetAddr.roleControlDel, params)
  },
  // 角色管理 查询所有角色
  roleControlAll(params) {
    return NetUtils.get("roleControlAll", NetAddr.roleControlAll, params)
  },
  // 角色管理 查询角色权限
  roleControlAuthList(params) {
    return NetUtils.get("roleControlAuthList", NetAddr.roleControlAuthList, params)
  },
  // 角色管理 更新角色权限
  roleControlAuthAdd(params) {
    return NetUtils.post("roleControlAuthAdd", NetAddr.roleControlAuthAdd, params, true)
  },
  // 账户管理 分页查询
  accountControlList(params) {
    return NetUtils.get("accountControlList", NetAddr.accountControlList, params)
  },
  // 账户管理 新增或修改
  accountControlAdd(params) {
    return NetUtils.post("accountControlAdd", NetAddr.accountControlAdd, params, true)
  },
  // 账户管理 删除
  accountControlDel(params) {
    return NetUtils.post("accountControlDel", NetAddr.accountControlDel, params)
  },
  // 账户管理 更新角色
  accountControlUpdate(params) {
    return NetUtils.post("accountControlUpdate", NetAddr.accountControlUpdate, params, true)
  },
  // 菜单管理 分页查询
  menuControlList(params) {
    return NetUtils.get("menuControlList", NetAddr.menuControlList, params)
  },
  // 菜单管理 查询用户菜单（树形）
  menuControlListAll(params) {
    return NetUtils.get("menuControlListAll", NetAddr.menuControlListAll, params)
  },
  // 菜单管理 新增或修改
  menuControlAdd(params) {
    return NetUtils.post("menuControlAdd", NetAddr.menuControlAdd, params, true)
  },
  // 菜单管理 删除
  menuControlDel(params) {
    return NetUtils.post("menuControlDel", NetAddr.menuControlDel, params)
  },
  // 系统参数管理 分页查询
  systemParamList(params) {
    return NetUtils.get("systemParamList", NetAddr.systemParamList, params)
  },
  // 系统参数管理 新增或修改
  systemParamAdd(params) {
    return NetUtils.post("systemParamAdd", NetAddr.systemParamAdd, params, true)
  },
  // 系统参数管理 删除
  systemParamDel(params) {
    return NetUtils.post("systemParamDel", NetAddr.systemParamDel, params)
  },
  // 操作记录 分页查询
  systemRecordList(params) {
    return NetUtils.get("systemRecordList", NetAddr.systemRecordList, params)
  },
  // 用户明细 分页查询
  userDetailList(params) {
    return NetUtils.get("userDetailList", NetAddr.userDetailList, params)
  },
  // 用户明细 用户总数信息
  userDetailAll(params) {
    return NetUtils.get("userDetailAll", NetAddr.userDetailAll, params)
  },
  // 用户明细 分页查询下线信息
  userDetailDown(params) {
    return NetUtils.get("userDetailDown", NetAddr.userDetailDown, params)
  },
  // 用户明细 导出数据
  userDetailExport(params) {
    return NetUtils.post("userDetailExport", NetAddr.userDetailExport, params, false, { "responseType": "blob" })
  },
  // 用户明细 状态修改
  userDetailUpdate(params) {
    return NetUtils.post("userDetailUpdate", NetAddr.userDetailUpdate, params, false)
  },
  // 卡明细 分页查询
  cardDetailList(params) {
    return NetUtils.get("cardDetailList", NetAddr.cardDetailList, params)
  },
  // 卡明细 卡总数信息
  cardDetailAll(params) {
    return NetUtils.get("cardDetailAll", NetAddr.cardDetailAll, params)
  },
  // 卡明细 导出数据
  cardDetailExport(params) {
    return NetUtils.post("cardDetailExport", NetAddr.cardDetailExport, params, false, { "responseType": "blob" })
  },
  // 卡消费明细 分页查询
  cardSpendingList(params) {
    return NetUtils.get("cardSpendingList", NetAddr.cardSpendingList, params)
  },
  // 卡消费明细 卡账单总数信息
  cardSpendingAll(params) {
    return NetUtils.get("cardSpendingAll", NetAddr.cardSpendingAll, params)
  },
  // 卡消费明细 导出数据
  cardSpendingExport(params) {
    return NetUtils.post("cardSpendingExport", NetAddr.cardSpendingExport, params, false, { "responseType": "blob" })
  },
  // 卡充值明细 查询所有
  cardTopupDetailList(params) {
    return NetUtils.get("cardTopupDetailList", NetAddr.cardTopupDetailList, params)
  },
  // 卡充值明细 充值总数信息
  cardTopupDetailAll(params) {
    return NetUtils.get("cardTopupDetailAll", NetAddr.cardTopupDetailAll, params)
  },
  // 卡消费明细 导出数据
  cardTopupDetailExport(params) {
    return NetUtils.post("cardTopupDetailExport", NetAddr.cardTopupDetailExport, params, false, { "responseType": "blob" })
  },
  // 卡充值配置 查询
  cardTopupList(params) {
    return NetUtils.get("cardTopupList", NetAddr.cardTopupList, params)
  },
  // 卡充值配置 修改充值限制
  cardTopupAdd(params) {
    return NetUtils.post("cardTopupAdd", NetAddr.cardTopupAdd, params)
  },
  // 卡权益配置 查询所有
  cardEquityConfigList(params) {
    return NetUtils.get("cardEquityConfigList", NetAddr.cardEquityConfigList, params)
  },
  // 卡权益配置 新增或修改
  cardEquityConfigAdd(params) {
    return NetUtils.post("cardEquityConfigAdd", NetAddr.cardEquityConfigAdd, params, true)
  },
  // 卡权益配置 卡等级编号 查询详情
  cardEquityClassList(params) {
    return NetUtils.get("cardEquityClassList", NetAddr.cardEquityClassList, params)
  },
  // 卡权益配置 卡等级编号 新增或修改
  cardEquityClassAdd(params) {
    return NetUtils.post("cardEquityClassAdd", NetAddr.cardEquityClassAdd, params, true)
  },
  // 卡权益配置 卡等级编号 删除
  cardEquityClassDel(params) {
    return NetUtils.post("cardEquityClassDel", NetAddr.cardEquityClassDel, params)
  },
  // 邀请明细 分页查询
  invitationDetailList(params) {
    return NetUtils.get("invitationDetailList", NetAddr.invitationDetailList, params)
  },
  // 邀请明细 关系总数信息
  invitationDetailAll(params) {
    return NetUtils.get("invitationDetailAll", NetAddr.invitationDetailAll, params)
  },
  // 邀请明细 导出数据
  invitationDetailExport(params) {
    return NetUtils.post("invitationDetailExport", NetAddr.invitationDetailExport, params, false, { "responseType": "blob" })
  },
  // 分佣明细 分页查询
  giveawayDetailList(params) {
    return NetUtils.get("giveawayDetailList", NetAddr.giveawayDetailList, params)
  },
  // 分佣明细 分销关系总数信息
  giveawayDetailAll(params) {
    return NetUtils.get("giveawayDetailAll", NetAddr.giveawayDetailAll, params)
  },
  // 分佣明细 导出数据
  giveawayDetailExport(params) {
    return NetUtils.post("giveawayDetailExport", NetAddr.giveawayDetailExport, params, false, { "responseType": "blob" })
  },
  // 分佣比例配置 分销代理用户一级分成比例
  giveawayRatioFirst(params) {
    return NetUtils.get("giveawayRatioFirst", NetAddr.giveawayRatioFirst, params)
  },
  // 分佣比例配置 分页查询
  giveawayRatioList(params) {
    return NetUtils.get("giveawayRatioList", NetAddr.giveawayRatioList, params)
  },
  // 分佣比例配置 修改代理人佣金比例
  giveawayRatioAddAgent(params) {
    return NetUtils.post("giveawayRatioAddAgent", NetAddr.giveawayRatioAddAgent, params)
  },
  // 分佣比例配置 修改合伙人佣金比例
  giveawayRatioAddPartner(params) {
    return NetUtils.post("giveawayRatioAddPartner", NetAddr.giveawayRatioAddPartner, params, true)
  },
  // 合伙人配置 分页查询
  partnerConfigList(params) {
    return NetUtils.get("partnerConfigList", NetAddr.partnerConfigList, params)
  },
  // 合伙人配置 新增或修改
  partnerConfigAdd(params) {
    return NetUtils.post("partnerConfigAdd", NetAddr.partnerConfigAdd, params, true)
  },
  // 合伙人配置 删除
  partnerConfigDel(params) {
    return NetUtils.post("partnerConfigDel", NetAddr.partnerConfigDel, params)
  },
  // 黑卡资格名单 分页查询
  blackCardList(params) {
    return NetUtils.get("blackCardList", NetAddr.blackCardList, params)
  },
  // 黑卡资格名单 新增或修改
  blackCardAdd(params) {
    return NetUtils.post("blackCardAdd", NetAddr.blackCardAdd, params, true)
  },
  // 黑卡资格名单 导出数据
  blackCardExport(params) {
    return NetUtils.post("blackCardExport", NetAddr.blackCardExport, params, false, { "responseType": "blob" })
  },
  // 钱包管理
  // 充币明细 分页查询
  coinTopupDetailList(params) {
    return NetUtils.get("coinTopupDetailList", NetAddr.coinTopupDetailList, params)
  },
  // 充币明细 充币统计
  coinTopupDetailAll(params) {
    return NetUtils.get("coinTopupDetailAll", NetAddr.coinTopupDetailAll, params)
  },
  // 充币明细 导出数据
  coinTopupDetailExport(params) {
    return NetUtils.post("coinTopupDetailExport", NetAddr.coinTopupDetailExport, params, false, { "responseType": "blob" })
  },
  // 兑币明细 分页查询
  coinExchangeDetailList(params) {
    return NetUtils.get("coinExchangeDetailList", NetAddr.coinExchangeDetailList, params)
  },
  // 兑币明细 记录统计
  coinExchangeDetailAll(params) {
    return NetUtils.get("coinExchangeDetailAll", NetAddr.coinExchangeDetailAll, params)
  },
  // 兑币明细 导出数据
  coinExchangeDetailExport(params) {
    return NetUtils.post("coinExchangeDetailExport", NetAddr.coinExchangeDetailExport, params, false, { "responseType": "blob" })
  },
  // 提币明细 分页查询
  withdrawDetailList(params) {
    return NetUtils.get("withdrawDetailList", NetAddr.withdrawDetailList, params)
  },
  // 提币明细 提币统计
  withdrawDetailAll(params) {
    return NetUtils.get("withdrawDetailAll", NetAddr.withdrawDetailAll, params)
  },
  // 提币明细 导出数据
  withdrawDetailExport(params) {
    return NetUtils.post("withdrawDetailExport", NetAddr.withdrawDetailExport, params, false, { "responseType": "blob" })
  },
  // 兑率/手续费配置 分页查询
  coinExchangeRateList(params) {
    return NetUtils.get("coinExchangeRateList", NetAddr.coinExchangeRateList, params)
  },
  // 兑率/手续费配置 新增或修改
  coinExchangeRateAdd(params) {
    return NetUtils.post("coinExchangeRateAdd", NetAddr.coinExchangeRateAdd, params, true)
  },
  // 兑率/手续费配置 删除
  coinExchangeRateDel(params) {
    return NetUtils.post("coinExchangeRateDel", NetAddr.coinExchangeRateDel, params)
  },
  // 币的主网配置 分页查询
  coinNetworkList(params) {
    return NetUtils.get("coinNetworkList", NetAddr.coinNetworkList, params)
  },
  // 币的主网配置 新增或修改
  coinNetworkAdd(params) {
    return NetUtils.post("coinNetworkAdd", NetAddr.coinNetworkAdd, params, true)
  },
  // 币的主网配置 删除
  coinNetworkDel(params) {
    return NetUtils.post("coinNetworkDel", NetAddr.coinNetworkDel, params)
  },
  // PayPal转账 分页查询
  transferMoneyList(params) {
    return NetUtils.get("transferMoneyList", NetAddr.transferMoneyList, params)
  },
  // PayPal转账 新增或修改
  transferMoneyAdd(params) {
    return NetUtils.post("transferMoneyAdd", NetAddr.transferMoneyAdd, params, true)
  },
  // PayPal转账 删除复制
  transferMoneyDel(params) {
    return NetUtils.post("transferMoneyDel", NetAddr.transferMoneyDel, params)
  },
  // PayPal转账 转账审核
  transferMoneyAudit(params) {
    return NetUtils.post("transferMoneyAudit", NetAddr.transferMoneyAudit, params)
  },
  // PayPal转账 导出数据
  transferMoneyExport(params) {
    return NetUtils.post("transferMoneyExport", NetAddr.transferMoneyExport, params, false, { "responseType": "blob" })
  },
  // 支付宝转账 分页查询
  alipayMoneyList(params) {
    return NetUtils.get("alipayMoneyList", NetAddr.alipayMoneyList, params)
  },
  // 支付宝转账 导出数据
  alipayMoneyExport(params) {
    return NetUtils.post("alipayMoneyExport", NetAddr.alipayMoneyExport, params, true, { "responseType": "blob" })
  },
  // 转账功能开通明细 分页查询
  transferOpenDetailsList(params) {
    return NetUtils.get("transferOpenDetailsList", NetAddr.transferOpenDetailsList, params)
  },
  // 转账管理 分页查询
  transferConfigList(params) {
    return NetUtils.get("transferConfigList", NetAddr.transferConfigList, params)
  },
  // 转账管理 导出数据
  transferConfigExport(params) {
    return NetUtils.post("transferConfigExport", NetAddr.transferConfigExport, params, false, { "responseType": "blob" })
  },
  // 转账管理 获取文件数据
  transferConfigFileData(params) {
    return NetUtils.post("transferConfigFileData", NetAddr.transferConfigFileData, params, false, {
      "Content-Type": "multipart/form-data"
    })
  },
  // 转账管理 开始转账
  transferConfigInitTransfer(params) {
    return NetUtils.post("transferConfigInitTransfer", NetAddr.transferConfigInitTransfer, params, false, {
      "Content-Type": "multipart/form-data"
    })
  },
  // 转账管理 重新转账
  transferConfigReTransfer(params) {
    return NetUtils.post("transferConfigReTransfer", NetAddr.transferConfigReTransfer, params)
  },
  // 用户余额明细 分页查询
  balanceDetailList(params) {
    return NetUtils.get("balanceDetailList", NetAddr.balanceDetailList, params)
  },
  // 用户余额明细 导出数据
  balanceDetailExport(params) {
    return NetUtils.post("balanceDetailExport", NetAddr.balanceDetailExport, params, false, { "responseType": "blob" })
  },
  // 用户余额明细 用户钱包扣款
  balanceDetailDeduction(params) {
    return NetUtils.post("balanceDetailDeduction", NetAddr.balanceDetailDeduction, params, true)
  },
  // 用户余额明细 分页查询USDT钱包记录
  balanceDetailUSDTList(params) {
    return NetUtils.get("balanceDetailUSDTList", NetAddr.balanceDetailUSDTList, params)
  },
  // 用户余额明细 用户USDT钱包记录导出
  balanceDetailUSDTExport(params) {
    return NetUtils.post("balanceDetailUSDTExport", NetAddr.balanceDetailUSDTExport, params, false, { "responseType": "blob" })
  },
  // 用户余额明细 分页查询法币钱包记录
  balanceDetailEURList(params) {
    return NetUtils.get("balanceDetailEURList", NetAddr.balanceDetailEURList, params)
  },
  // 用户余额明细 用户法币钱包记录导出
  balanceDetailEURExport(params) {
    return NetUtils.post("balanceDetailEURExport", NetAddr.balanceDetailEURExport, params, false, { "responseType": "blob" })
  },

  // 独立功能
  // 验证码
  creatVerifyCode(params) {
    return NetUtils.post("creatVerifyCode", NetAddr.creatVerifyCode, params, true)
  },
  // 上传图片、文件
  upload(params) {
    return NetUtils.post("upload", NetAddr.upload, params, false, {
      "Content-Type": "multipart/form-data"
    })
  }
}
