import axios from 'axios'
import { ElMessage } from 'element-plus'
import router from '@/router/index.js'

// axios 配置
var instance = axios.create({
  timeout: 20000,
  baseURL: ''
})

// 添加请求拦截器
instance.interceptors.request.use(config => {
  // 在发送请求之前做些什么，比如传token
  detailRepeatUrl(config)
  // removeRepeatUrl(config)       //在一个ajax发送前执行一下取消操作
  // saveRepeatUrl(config)         //保存请求地址
  return config
}, error => {
  // 对请求错误做些什么
  console.log('req-err', error)
  return Promise.reject(error)
})

// 添加响应拦截器
instance.interceptors.response.use(response => {
  // 对响应数据做点什么
  // removeRepeatUrl(response.config)        //执行取消操作，把已经完成的请求从pending中移除
  // const res = response.data
  // 对错误代码做处理
  return response
}, error => {
  // 对响应错误做点什么
  console.log('res-err', error)
  return Promise.reject(error)
})

let isExport = url => {
  const reg = /(\/export)(.*)/g
  return reg.test(url)
}

let detailRepeatUrl = (config) => {
  let params = config.params || config.data
  if (config.method === 'get') {
    if (Object.prototype.toString.call(params) === '[object Object]') {
      if (params.reqTimeOut) {
        config.timeout = params.reqTimeOut
      }
    }
  } else if (config.method === 'post') {
    if (Object.prototype.toString.call(params) === '[object FormData]') {
      if (params.has('reqTimeOut')) {
        config.timeout = params.get('reqTimeOut')
      }
    }
  }
}

let detailError = (data, error, reject) => {
  if (error && error.response) {
    switch (error.response.code) {
    case 400:
      error.message = '错误请求'
      break
    default:
      error.message = `连接错误${error.response.code}`
      break
    }
  } else {
    if (JSON.stringify(error).includes('timeout')) {
      error.message = '服务器响应超时'
    } else {
      error.message = '链接服务器失败'
    }
  }
  ElMessage.error(error.message)
  reject && reject()
}

let successMsg = (hideToast, response, reject) => {
  // console.log(hideToast, response, reject)
  let res = response.data
  if (isExport(response.config.url)) {
    if (hideToast !== true) {
      if (res.code === 500) {
        ElMessage.error(res.msg || res.data || '错误请求')
        return
      }
    }
  } else {
    if (hideToast !== true) {
      if (res.code !== 200 && res.code !== 2000) {
        ElMessage.error(res.msg || res.data || '错误请求')
      }
    }
  }
  if (res.code === 500 && res.msg === 'token已过期，请重新登录') {
    router.push({
      path: '/login',
      query: {
        // redirect: router.currentRoute.path
      }
    })
  } else {
    reject && reject(res)
  }
}

/**
 * 封装所有请求
 * @param methed
 * @param url
 * @param data
 * @param headers
 * @returns {Promise}
 */
export default class NetUtils {
  // get请求
  static get (name, url, data = {}, headers = {}) {
    let hideToast = false
    if (data && data.hideToast === true) {
      hideToast = true
      delete data.hideToast
    }
    if (data && data.timeout) {
      instance.defaults.timeout = data.timeout
      delete data.timeout
    }
    headers = {
      Authorization: window.controlToken || '',
      ...headers
    }
    let newInfo = {}
    if (isExport(url)) {
      newInfo.responseType = 'blob'
    }
    return new Promise((resolve, reject) => {
      instance({
        method: 'get',
        url: url,
        params: data,
        headers: headers,
        ...newInfo
      }).then(response => {
        console.log(`${name}-suc`, data, response, response.data)
        successMsg(hideToast, response, resolve)
        // resolve(response.data)
      }).catch(err => {
        console.log(`${name}-err`, data, err)
        detailError(data, err, reject(err))
      })
    })
  }
  // post请求
  static post (name, url, data = {}, isJson, headers = {}) {
    let hideToast = false
    if (data && data.hideToast === true) {
      hideToast = true
      delete data.hideToast
    }
    if (data && data.timeout) {
      instance.defaults.timeout = data.timeout
      delete data.timeout
    }
    if (isJson === true && data) {
      data = JSON.stringify(data)
    } else {
      let params = new FormData()
      if (data && isJson !== true) {
        Object.keys(data).forEach((key) => {
          params.append(key, data[key])
        })
      }
      data = params
    }
    headers = {
      Accept: 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: window.controlToken || '',
      ...headers
    }
    if (isJson === true) {
      headers['Content-Type'] = 'application/json;charset=utf8'
    }
    let newInfo = {}
    if (isExport(url)) {
      newInfo.responseType = 'blob'
    }
    return new Promise((resolve, reject) => {
      instance({
        method: 'post',
        url: url,
        data: data || '',
        headers: headers,
        ...newInfo
      }).then(response => {
        console.log(`${name}-suc`, data, response, response.data)
        successMsg(hideToast, response, resolve)
      }).catch(err => {
        console.log(`${name}-err`, data, err)
        detailError(data, err, reject(err))
      })
    })
  }
}
