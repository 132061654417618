import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/login',
    name: "Login",
    component: () => import("../container/login/LoginPage.vue")
  }, {
    path: '/content',
    name: 'ContentPage',
    component: () => import("../container/ContentPage.vue"),
    children: [
      {
        // 用户管理 用户明细
        path: "userDetails",
        component: () => import("../container/userManagement/userDetails/UserDetailsPage.vue"),
        name: "UserDetailsPage"
      }, {
        // 分销管理 邀请明细
        path: "invitationDetails",
        component: () => import("../container/distributionManagement/invitationDetails/InvitationDetailPage.vue"),
        name: "InvitationDetailPage"
      }, {
        // 分销管理 分佣明细
        path: "giveawayDetails",
        component: () => import("../container/distributionManagement/giveawayDetails/GiveawayDetailsPage.vue"),
        name: "GiveawayDetailsPage"
      }, {
        // 分销管理 分佣比例配置
        path: "giveawayRatioConfig",
        component: () => import("../container/distributionManagement/giveawayRatioConfig/GiveawayRatioConfigPage.vue"),
        name: "GiveawayRatioConfigPage"
      }, {
        // 分销管理 合伙人配置
        path: "partnerConfig",
        component: () => import("../container/distributionManagement/partnerConfig/PartnerConfigPage.vue"),
        name: "PartnerConfigPage"
      }, {
        // 分销管理 黑卡资格名单
        path: "blackCardList",
        component: () => import("../container/distributionManagement/blackCardList/BlackCardListPage.vue"),
        name: "BlackCardListPage"
      }, {
        // 卡管理 卡明细
        path: "cardDetails",
        component: () => import("../container/cardManagement/cardDetails/CardDetailsPage.vue"),
        name: "CardDetailsPage"
      }, {
        // 卡管理 卡消费明细
        path: "cardSpendingDetails",
        component: () => import("../container/cardManagement/cardSpendingDetails/CardSpendingDetailsPage.vue"),
        name: "CardSpendingDetailsPage"
      }, {
        // 卡管理 卡充值明细
        path: "cardTopupDetails",
        component: () => import("../container/cardManagement/topupDetails/CardTopupDetailsPage.vue"),
        name: "CardTopupDetailsPage"
      }, {
        // 卡管理 卡充值配置
        path: "cardTopupConfig",
        component: () => import("../container/cardManagement/topupConfig/TopupConfigPage.vue"),
        name: "TopupConfigPage"
      }, {
        // 卡管理 卡权益配置
        path: "equityConfig",
        component: () => import("../container/cardManagement/equityConfig/EquityConfigPage.vue"),
        name: "EquityConfigPage"
      }, {
        // 钱包管理 充币明细
        path: "topupDetails",
        component: () => import("../container/walletManagement/topupDetails/TopupDetailsPage.vue"),
        name: "TopupDetailsPage"
      }, {
        // 钱包管理 兑币明细
        path: "exchangeDetails",
        component: () => import("../container/walletManagement/exchangeDetails/ExchangeDetailsPage.vue"),
        name: "ExchangeDetailsPage"
      }, {
        // 钱包管理 提币明细
        path: "withdrawDetails",
        component: () => import("../container/walletManagement/withdrawDetails/WithdrawDetailsPage.vue"),
        name: "WithdrawDetailsPage"
      }, {
        // 钱包管理 兑率/手续费配置
        path: "exchangeRateConfig",
        component: () => import("../container/walletManagement/exchangeRateConfig/ExchangeRateConfigPage.vue"),
        name: "ExchangeRateConfigPage"
      }, {
        // 钱包管理 币的主网配置
        path: "coinNetworkConfig",
        component: () => import("../container/walletManagement/coinNetworkConfig/CoinNetworkConfigPage.vue"),
        name: "CoinNetworkConfigPage"
      }, {
        // 钱包管理 PayPal转账
        path: "transferMoney",
        component: () => import("../container/walletManagement/transferMoney/TransferMoneyPage.vue"),
        name: "TransferMoneyPage"
      }, {
        // 钱包管理 支付宝转账
        path: "alipayMoney",
        component: () => import("../container/walletManagement/alipayMoney/AlipayMoneyPage.vue"),
        name: "AlipayMoneyPage"
      }, {
        // 钱包管理 转账功能开通明细
        path: "transferOpenDetails",
        component: () => import("../container/walletManagement/transferOpenDetails/TransferOpenDetailsPage.vue"),
        name: "TransferOpenDetailsPage"
      }, {
        // 钱包管理 转账管理
        path: "transferConfig",
        component: () => import("../container/walletManagement/transferConfig/TransferConfigPage.vue"),
        name: "TransferConfigPage"
      }, {
        // 钱包管理 用户余额明细
        path: "balanceDetail",
        component: () => import("../container/walletManagement/balanceDetail/BalanceDetailPage.vue"),
        name: "BalanceDetailPage"
      }, {
        // 钱包管理 用户余额详情
        path: "balanceDetails/:type/:userid",
        component: () => import("../container/walletManagement/balanceDetail/BalanceDetailsPage.vue"),
        name: "BalanceDetailsPage"
      }, {
        // 系统管理 角色/权限管理
        path: "roleControlment",
        component: () => import("../container/systemsManagment/roleControlment/RoleControlmentPage.vue"),
        name: "RoleControlmentPage"
      }, {
        // 系统管理 账号管理
        path: "accountControlment",
        component: () => import("../container/systemsManagment/accountControlment/AccountControlmentPage.vue"),
        name: "AccountControlmentPage"
      }, {
        // 系统管理 菜单管理
        path: "menuButtonControlment",
        component: () => import("../container/systemsManagment/menuButtonControlment/MenuButtonControlmentPage.vue"),
        name: "MenuButtonControlmentPage"
      }, {
        // 系统管理 系统参数管理
        path: "systemParameter",
        component: () => import("../container/systemsManagment/systemParameter/SystemParameterPage.vue"),
        name: "SystemParameterPage"
      }, {
        // 系统管理 操作记录
        path: "operationRecord",
        component: () => import("../container/systemsManagment/operationRecord/OperationRecordPage.vue"),
        name: "OperationRecordPage"
      }
    ],
    meta: {
      auth: true,
      isScroll: true
    }
  }, {
    path: '/:catchAll(.*)',
    redirect: '/login'
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

// 路由守卫
router.beforeEach((to, from, next) => {
  // console.log("路由守卫：", to, from)
  let control_token = sessionStorage.getItem("vcard_control_token")
  if (to.meta.auth) {
    if (control_token) {
      next()
    } else {
      next({
        path: "/login",
        query: {
          redirect: to.fullPath
        }
      })
    }
  } else {
    next()
  }
})

router.afterEach((to, from) => {
  if (to.meta.isScroll) {
    document.documentElement.scrollTop = 0
    document.documentElement.scrollLeft = 0
  }
})

export default router
