<template>
  <router-view />
</template>
<script>
export default {
  name: "App",
  created() {
    window.controlToken = sessionStorage.getItem("vcard_control_token")
    if (sessionStorage.getItem('vcard_control_store')) {
      this.$store.replaceState(Object.assign({}, this.$store.state, JSON.parse(sessionStorage.getItem('vcard_control_store'))))
    }
    // 在页面刷新时保存vuex里的信息
    window.addEventListener('beforeunload', () => {
      sessionStorage.setItem('vcard_control_store', JSON.stringify(this.$store.state))
    })
  }
}
</script>
<style lang="scss">
@import "./styles/common.scss";
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
