import { createStore } from 'vuex'

export default createStore({
  state: {
    login: false,  // 是否登录
    controlToken: null,    //token
    menuList: null,   // 菜单列表
    sysUser: null,  // 用户信息
    pageSize: 200 // 页码
  },
  getters: {
    getLoginStatus(state) {
      return state.login
    }
  },
  mutations: {
    addUserInfo(state, info) {
      state.login = true
      state.controlToken = info.controlToken
      state.menuList = info.menuButtonList
      state.sysUser = info.sysUser
    },
    setPageSize(state, num) {
      state.pageSize = num
    }
  },
  actions: {
  },
  modules: {
  }
})
